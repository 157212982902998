<template>
  <div>
    <div class="search_container">
      <div>
        <span>资源类型：</span>
        <a-select v-model="resourceType" class="input_width">
          <a-select-option
            v-for="(item, index) in resourceTypeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>资源名称：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="instanceName"
        />
      </div>
      <div>
        <span>维护人：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="director"
        />
      </div>
      <div>
        <span>租户到期时间：</span>
        <a-range-picker
          v-model="expireDate"
          class="input_width"
          @change="onTimeChange"
        />
      </div>
    </div>
    <div class="search_container add_button">
      <div>
        <a-button @click="openModel('add')" type="primary"> 新增资源 </a-button>
      </div>
      <div class="blank_contain" style="text-align: right">
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
          :scroll="{y: 379 }"
      >
        <span slot="resourceType" slot-scope="text">
          <span v-if="text === 1">MySQL</span>
          <span v-if="text === 2">MongoDB</span>
          <span v-if="text === 3">Redis</span>
          <span v-if="text === 4">RocketMQ</span>
          <span v-if="text === 5">Nebula</span>
          <span v-if="text === 6">InfluxDB</span>
        </span>
        <span slot="firm" slot-scope="text">
          <span v-if="text === 1">自有</span>
          <span v-if="text === 2">华为</span>
          <span v-if="text === 3">阿里</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a v-if="record.resourceStatus===0" @click="releaseResource(record)">发布</a>
          <a style="margin-left: 20px" @click="openModel('edit', record)"
            >编辑</a
          >
          <a style="margin-left: 20px" @click="openModel('look', record)">
            查看<!--  -->
          </a>
          <a style="margin-left: 20px" v-if="record.resourceStatus===0"  @click="openModel('change', record)">
            修改密码
          </a>
          <a style="margin-left: 20px"  @click="openModel('record', record)">
            维护记录
          </a>
          <a style="margin-left: 20px" v-if="record.resourceStatus===0"  @click="openModel('del', record)">
            删除
          </a>
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { sassResourceApi } from "@/api/sassResource.js";
export default {
  mixins: [pagination],
  components: {
    ModelTag,
  },
  data() {
    return {
      release: false,
      loading: false,
      resourceType: "",
      instanceName: "",
      director: "",
      expireDate: null,
      startExpireDate: "",
      endExpireDate: "",
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "",
        width: "60%",
        okText: "保存",
        delArr: [],
      },
      resourceTypeList: [
        {
          name: "MySQL",
          value: 1,
        },
        {
          name: "MongoDB",
          value: 2,
        },
        {
          name: "Redis",
          value: 3,
        },
        {
          name: "InfluxDB",
          value: 4,
        },
        {
          name: "Nebula",
          value: 5,
        },
        {
          name: "RocketMQ",
          value: 6,
        },
      ],

      columns: [
        {
          title: "资源类型",
          dataIndex: "resourceType",
          key: "resourceType",
          align: "center",
          scopedSlots: { customRender: "resourceType" },
          width:'140px'
        },

        {
          title: "资源名称",
          dataIndex: "instanceName",
          key: "instanceName ",
          align: "center",
          
        },
        {
          title: "来源",
          dataIndex: "firm",
          key: "firm",
          align: "center",
          scopedSlots: { customRender: "firm" },
            width:'140px'
        },
        {
          title: "实例ID",
          dataIndex: "instanceId",
          key: "instanceId",
          align: "center",
            width:'140px'
        },

        {
          title: "维护人",
          dataIndex: "director",
          key: "director",
          align: "center",
            width:'140px'
        },

        {
          title: "到期时间",
          dataIndex: "expireTime",
          key: "expireTime",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
            width:'440px'
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.expireDate = dateStrings;
      this.startExpireDate = dateStrings[0];
      this.endExpireDate = dateStrings[1];
    },
    //重置
    reset() {
      this.instanceName = "";
      this.resourceType = "";
      this.startExpireDate = "";
      this.endExpireDate = "";
      this.director = "";
      this.expireDate = null;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        resourceType: this.resourceType,
        director: this.director,
        endExpireDate: this.endExpireDate,
        startExpireDate: this.startExpireDate,
        instanceName: this.instanceName,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sassResourceApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //发布资源
    releaseResource(record) {
      this.loading = true;
      let params = {
        id: record.id,
        rowVersion: record.rowVersion,
      };
      sassResourceApi.releaseResource(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.$message.success("发布成功")
          this.reset();
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },
// publish(){

// },
    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "add") {
        this.modelConfig.title = "新增资源";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "800px";
      } else if (type === "del") {
        this.modelConfig.record = record;
        this.modelConfig.title = "删除资源";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "look") {
        this.modelConfig.record = record;
        this.modelConfig.title = "查看";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "record") {
        this.modelConfig.record = record;
        this.modelConfig.title = "维护记录";
        this.modelConfig.width = "1000px";
        this.modelConfig.okText = "确定";
      } else {
        this.modelConfig.record = record;
        this.modelConfig.title = "修改密码";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.modelConfig.show = false;
      if (this.modelConfig.type === "del") {
        this.$message.success("删除成功！");
      } else {
        this.$message.success("保存成功！");
      }
      this.getData();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dateStyle {
  color: red;
}
.input_width {
  width: 240px;
}
.blank_contain {
  width: 340px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>