<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="footer">
        <a-button
          key="back"
          v-if="config.type !== 'look' && config.type !== 'record'"
          @click="handleCancel"
        >
          取消
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
          v-if="config.type !== 'look' && config.type !== 'record'"
        >
          确定
        </a-button>
        <a-button
          v-if="config.type === 'look' || config.type === 'record'"
          key="back"
          type="primary"
          @click="handleCancel"
        >
          关闭
        </a-button>
      </template>
      <div v-if="config.type === 'del'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否删除此资源?</span>
        </div>
        <div class="modal_text modal_stext">
          资源名称：{{ config.record.instanceName }}
        </div>
      </div>
      <div v-if="config.type === 'add' || config.type === 'edit'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="资源类型" prop="resourceType">
            <a-select
              v-model="form.resourceType"
              :disabled="resourceStatus === 1"
              class="input_width"
            >
              <a-select-option
                v-for="(item, index) in resourceTypeList"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="资源名称" prop="instanceName">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.instanceName"
              :disabled="resourceStatus === 1"
            />
          </a-form-model-item>
          <a-form-model-item label="来源" prop="firm">
            <a-select
              v-model="form.firm"
              :disabled="resourceStatus === 1"
              class="input_width"
            >
              <a-select-option
                v-for="(item, index) in firmList"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="实例ID" prop="instanceId">
            <a-input
              placeholder="请输入"
              class="input_width"
              :disabled="resourceStatus === 1"
              v-model="form.instanceId"
            />
          </a-form-model-item>
          <a-form-model-item label="内网地址" prop="intranetAddress">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.intranetAddress"
              :disabled="resourceStatus === 1"
            />
          </a-form-model-item>
          <a-form-model-item label="端口" prop="intranetPort">
            <a-input-number
              placeholder="请输入"
              class="input_width"
              v-model="form.intranetPort"
              :step="1"
              :min="0"
              :precision="0"
              :disabled="resourceStatus === 1"
            />
          </a-form-model-item>
          <a-form-model-item label="用户名" prop="username">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.username"
              :disabled="resourceStatus === 1"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="config.type === 'add'"
            label="密码"
            prop="password"
          >
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.password"
            />
          </a-form-model-item>
          <a-form-model-item label="维护人" prop="director">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.director"
            />
          </a-form-model-item>
          <a-form-model-item label="CPU指标" prop="cpu">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.cpu"
            />
          </a-form-model-item>
          <a-form-model-item label="硬盘指标" prop="hardDisk">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.hardDisk"
            />
          </a-form-model-item>
          <a-form-model-item label="内存指标" prop="memory">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.memory"
            />
          </a-form-model-item>
          <a-form-model-item label="版本类型" prop="versionType">
            <a-select
              :disabled="resourceStatus === 1"
              v-model="form.versionType"
              class="input_width"
            >
              <a-select-option
                v-for="(item, index) in versionTypeList"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="版本" prop="version">
            <a-input
              :disabled="resourceStatus === 1"
              placeholder="请输入"
              class="input_width"
              v-model="form.version"
            />
          </a-form-model-item>
          <a-form-model-item label="到期时间" prop="expireTime">
            <a-date-picker
              show-time
              class="input_width"
              v-model="form.expireTime"
              @change="onTimeChange"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-if="config.type === 'record'">
        <a-table
          :loading="loading"
          :pagination="pagination"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="dataTable"
        >
          <span slot="action" slot-scope="text, record">
            <a @click="openModel('edit', record)">编辑</a>
            <a style="margin-left: 20px" @click="openModel('look', record)">
              查看
            </a>
            <a style="margin-left: 20px" @click="openModel('record', record)">
              维护记录
            </a>
            <a style="margin-left: 20px" @click="openModel('del', record)">
              删除
            </a>
          </span>
        </a-table>
      </div>
      <div v-if="config.type === 'look'">
        <div class="carriage">
          <div class="site right">资源类型：</div>
          <div class="site">{{ form.resourceType }}</div>
        </div>
        <div class="carriage">
          <div class="site right">资源名称：</div>
          <div class="site">{{ form.instanceName }}</div>
        </div>
        <div class="carriage">
          <div class="site right">来源：</div>
          <div class="site">{{ form.firm }}</div>
        </div>
        <div class="carriage">
          <div class="site right">实例ID：</div>
          <div class="site">{{ form.instanceId }}</div>
        </div>
        <div class="carriage">
          <div class="site right">内网地址：</div>
          <div class="site">{{ form.intranetAddress }}</div>
        </div>
        <div class="carriage">
          <div class="site right">端口：</div>
          <div class="site">{{ form.intranetPort }}</div>
        </div>
        <div class="carriage">
          <div class="site right">用户名：</div>
          <div class="site">{{ form.username }}</div>
        </div>
        <div class="carriage">
          <div class="site right">密码：</div>
          <div class="site">
            {{ form.password }}
            <a-button
              style="margin-left: 20px"
              @click="openInnerModel('get')"
              type="primary"
            >
              查看密码
            </a-button>
          </div>
        </div>

        <div class="carriage">
          <div class="site right">CPU指标：</div>
          <div class="site">{{ form.cpu }}</div>
        </div>
        <div class="carriage">
          <div class="site right">硬盘指标：</div>
          <div class="site">{{ form.hardDisk }}</div>
        </div>
        <div class="carriage">
          <div class="site right">内存指标：</div>
          <div class="site">{{ form.memory }}</div>
        </div>
        <div class="carriage">
          <div class="site right">版本类型：</div>
          <div class="site" v-if="form.versionType === 1">标准版</div>
          <div class="site" v-if="form.versionType === 2">专业版</div>
        </div>
        <div class="carriage">
          <div class="site right">版本：</div>
          <div class="site">{{ form.version }}</div>
        </div>
        <div class="carriage">
          <div class="site right">到期时间：</div>
          <div class="site">{{ form.expireTime }}</div>
        </div>
      </div>
      <div style="height: 100px" v-if="config.type === 'change'">
        <div v-if="status">
          <div class="top_text top">请输入登录密码后点击下一步</div>
          <div class="top_text">
            <a-input placeholder="请输入" v-model="password" />
          </div>

          <transition name="top-fade">
            <div v-if="error" class="top_text notice">
              密码不正确，请重新输入!
            </div>
            <div v-if="empty" class="top_text notice">密码不能为空!</div>
          </transition>
        </div>
        <div v-else>
          <div class="top_text">
            设置密码：
            <a-input
              style="width: 300px; margin-top: 30px"
              placeholder="请输入"
              v-model="newPassword"
            />
          </div>

          <transition name="top-fade">
            <div v-if="error" class="bottom_text">密码不正确，请重新输入!</div>
            <div v-if="empty" class="bottom_text">密码不能为空!</div>
          </transition>
        </div>
      </div>
    </a-modal>
    <InnerModel
      v-if="modelConfig.show"
      @innerHandleCancel="innerHandleCancel"
      @innerHandleOk="innerHandleOk"
      :config="modelConfig"
    ></InnerModel>
  </div>
</template>

<script>
import { sassResourceApi } from "@/api/sassResource.js";
import pagination from "@/mixins/Pagination";
import InnerModel from "./innerModel.vue";
export default {
  mixins: [pagination],
  components: {
    InnerModel,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    //校验手机号
    // let validatorPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("手机号码不能为空"));
    //   } else if (!/^1[3456789]\d{9}$/.test(value) && value) {
    //     callback(new Error("手机号码格式不正确"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      resourceStatus:null,
      // editStatus:false,
      error: false,
      empty: false,
      password: "",
      newPassword: "",
      dataTable: [],
      loading: false,
      confirmLoading: false,
      rowVersion: "",
      status: true,
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "",
        width: "60%",
        okText: "保存",
        delArr: [],
        password: "",
      },
      resourceTypeList: [
        {
          name: "MySQL",
          value: 1,
        },
        {
          name: "MongoDB",
          value: 2,
        },
        {
          name: "Redis",
          value: 3,
        },
        {
          name: "RocketMQ",
          value: 4,
        },
        {
          name: "Nebula",
          value: 5,
        },
        {
          name: "InfluxDB",
          value: 6,
        },
      ],
      firmList: [
        {
          name: "自有",
          value: 1,
        },
        {
          name: "华为",
          value: 2,
        },
        {
          name: "阿里",
          value: 3,
        },
      ],
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      versionTypeList: [
        {
          name: "标准版",
          value: 1,
        },
        {
          name: "专业版",
          value: 2,
        },
      ],
      form: {
        resourceType: "",
        instanceName: "",
        firm: "",
        instanceId: "",
        intranetAddress: "",
        intranetPort: "",
        username: "",
        password: "",
        director: "",
        cpu: "",
        hardDisk: "",
        memory: "",
        version: "",
        expireTime: "",
        versionType: "",
      },
      rules: {
        resourceType: [
          {
            required: true,
            message: "资源类型不能为空",
            trigger: "blur",
          },
        ],
        instanceName: [
          {
            required: true,
            message: "资源名称不能为空",
            trigger: "blur",
          },
            {
            pattern: /^(?!_|[0-9])[a-zA-Z0-9_\u4e00-\u9fa5]{2,50}$/,
            message:
              "仅支持中文、英文、数字、下划线（_），且只能以英文或中文开头，2~50个字符",
            trigger: "blur",
          },
        ],
        firm: [
          {
            required: true,
            message: "来源不能为空",
            trigger: "blur",
          },
        ],
        instanceId: [
          {
            required: true,
            message: "实例ID不能为空",
            trigger: "blur",
          },
        ],
        intranetAddress: [
          {
            required: true,
            message: "内网地址不能为空",
            trigger: "blur",
          },
        ],
        intranetPort: [
          {
            required: true,
            message: "端口不能为空",
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        director: [
          {
            required: true,
            message: "维护人不能为空",
            trigger: "blur",
          },
        ],
        cpu: [
          {
            required: true,
            message: "cpu指标不能为空",
            trigger: "blur",
          },
        ],
        hardDisk: [
          {
            required: true,
            message: "硬盘指标不能为空",
            trigger: "blur",
          },
        ],
        memory: [
          {
            required: true,
            message: "内存指标不能为空",
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: "版本不能为空",
            trigger: "blur",
          },
        ],
        versionType: [
          {
            required: true,
            message: "版本类型不能为空",
            trigger: "blur",
          },
        ],
        expireTime: [
          {
            required: true,
            message: "到期时间不能为空",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          title: "维护时间",
          dataIndex: "maintenanceTime",
          key: "maintenanceTime",
          align: "center",
        },

        {
          title: "维护人",
          dataIndex: "maintainer",
          key: "maintainer",
          align: "center",
        },
        {
          title: "维护内容",
          dataIndex: "content",
          key: "content",
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.inintModal();
  },
  created() {},
  watch: {},
  methods: {
    async openInnerModel(type, password) {
      this.modelConfig.type = type;
      this.modelConfig.id = this.config.id;
      this.modelConfig.width = "600px";
      this.modelConfig.title = "查看密码";
      if (type === "get") {
        this.modelConfig.title = "查看密码";
        this.modelConfig.show = true;
      } else {
        this.modelConfig.title = "查看密码";
        this.modelConfig.password = password;
        // await this.getPassword();
        this.modelConfig.show = true;
      }
    },
    async getPassword() {
      this.confirmLoading = true;
      let id = this.config.id;
      await sassResourceApi.getPassword(id).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.modelConfig.password = res.data;
          if (!this.status) {
            this.newPassword = res.data;
          }
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    setPassword() {
      this.confirmLoading = true;

      let params = {
        id: this.config.id,
        resourcePassword: this.newPassword,
        resourceOldPassword:this.modelConfig.password,
        rowVersion: this.rowVersion,
      };
      sassResourceApi.setPassword(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    inintModal() {
      if (this.config.type === "edit") {
        this.form.resourceType = this.config.record.resourceType;
        this.form.instanceName = this.config.record.instanceName;
        this.form.firm = this.config.record.firm;
        this.form.instanceId = this.config.record.instanceId;
        this.form.intranetAddress = this.config.record.intranetAddress;
        this.form.intranetPort = this.config.record.intranetPort;
        this.form.username = this.config.record.username;
        this.rowVersion = this.config.record.rowVersion;
        this.form.director = this.config.record.director;
        this.form.cpu = this.config.record.cpu;
        this.form.hardDisk = this.config.record.hardDisk;
        this.form.memory = this.config.record.memory;
        this.form.version = this.config.record.version;
        this.form.expireTime = this.config.record.expireTime;
        this.form.versionType = this.config.record.versionType;
        this.resourceStatus = this.config.record.resourceStatus;
      } else if (this.config.type === "record") {
        this.getData();
      } else if (this.config.type === "change") {
        this.rowVersion = this.config.record.rowVersion;
      } else if (this.config.type === "look") {
        this.detailResource();
      } else {
        return;
      }
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    innerHandleCancel() {
      this.modelConfig.show = false;
    },
    innerHandleOk(type = null, password) {
      this.modelConfig.show = false;
      if (type === "look") {
        this.openInnerModel("look", password);
      }
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.form.expireTime = dateStrings;
    },
    // 获取详细资源
    detailResource() {
      this.loading = true;
      let id = this.config.id;
      sassResourceApi.detailResource(id).then((res) => {
        if (res.success) {
          this.loading = false;
          let s = res.data.resourceType - 1;
          this.form.resourceType = this.resourceTypeList[s].name;
          this.form.instanceName = res.data.instanceName;
          this.form.firm = this.firmList[res.data.firm - 1].name;
          this.form.instanceId = res.data.instanceId;
          this.form.intranetAddress = res.data.intranetAddress;
          this.form.intranetPort = res.data.intranetPort;
          this.form.username = res.data.username;
          this.form.password = res.data.password;
          this.form.director = res.data.director;
          this.form.cpu = res.data.cpu;
          this.form.hardDisk = res.data.hardDisk;
          this.form.memory = res.data.memory;
          this.form.version = res.data.version;
          this.form.expireTime = res.data.expireTime;
          this.form.versionType = res.data.versionType;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        id: this.config.id,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sassResourceApi.getDetailTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //创建资源
    addResource() {
      this.confirmLoading = true;
      this.form.intranetPort = String(this.form.intranetPort);
      let params = {
        ...this.form,
      };
      sassResourceApi.addResource(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },

    //编辑资源
    editResource() {
      this.confirmLoading = true;
      let params = {
        ...this.form,
        id: this.config.id,
        rowVersion: this.rowVersion,
        resourceStatus: this.resourceStatus,
      };
      sassResourceApi.editResource(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },

    //删除资源
    delResource() {
      this.confirmLoading = true;
      let id = this.config.id;
      sassResourceApi.delResource(id).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //校验登陆密码
    checkPassword() {
      this.confirmLoading = true;
      // let password = this.password;
      let params = {
        password: this.password,
        id: this.config.id,
      };
      sassResourceApi.checkPassword(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;

          // this.getPassword();
          if (res.data.checkResult) {
            this.error = false;
            this.modelConfig.password = res.data.password;
            this.status = false;
          } else {
            this.error = true;
          }
        } else {
          this.confirmLoading = false;
          this.error = true;
          this.$message.error(res.errorMsg);
        }
      });
    },
        // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
    //确认按钮
    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addResource();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editResource();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "change") {
        if (this.status) {
          if (this.password) {
            this.checkPassword();
            this.empty = false;
          } else {
            this.empty = true;
          }
        } else {
          if (this.newPassword) {
            this.setPassword();
            this.empty = false;
          } else {
            this.empty = true;
          }
        }
      } else {
        this.delResource();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom_text {
  width: 400px;
  margin: auto;
  text-align: center;
  height: 30px;
  line-height: 30px;

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: red;

  margin-top: 32px;
  padding-left: 96px;
}
.top_text {
  width: 400px;
  margin: auto;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 900;
  // background-color: aquamarine;
}
.notice {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: red;
  // background-color: aquamarine;
}
.top {
  margin-bottom: 10px;
}
.form_item_contain {
  display: flex;
}
.form_item_span {
  margin-right: 20px;
}
.form_item_left {
  margin-right: 10px;
  // background-color: aqua;
  min-width: 110px;
}
.input_width {
  width: 350px;
}
.carriage {
  display: flex;
  width: 500px;
  // background-color: aqua;
  margin: auto;
  justify-content: space-between;
}
.site {
  width: 240px;
  height: 40px;
  line-height: 40px;
  // background-color: red;
}
.right {
  text-align: right;
}
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}

.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}

.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 10px;
  font-weight: 200;
  height: 60px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
.top-fade-enter, .top-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: opacity 0s;
}
</style>
