<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      :destroyOnClose="true"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="footer">
        <a-button key="back" v-if="config.type === 'get'" @click="handleCancel">
          取消
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
          v-if="config.type === 'get'"
        >
          下一步
        </a-button>
        <a-button
          v-if="config.type === 'look'"
          key="back"
          type="primary"
          @click="handleCancel"
        >
          关闭
        </a-button>
      </template>
      <div style="height: 100px" v-if="config.type === 'get'">
        <div class="top_text top">请输入登录密码后点击下一步</div>
        <div class="top_text">
          <a-input placeholder="请输入" v-model="password" />
        </div>
        <transition name="top-fade">
          <div v-if="error" class="top_text notice">
            密码不正确，请重新输入!
          </div>
          <div v-if="empty" class="top_text notice">密码不能为空!</div>
        </transition>
      </div>
      <div v-if="config.type === 'look'">
        <div class="carriage">
          <div class="site right">密码：</div>
          <div class="site">{{ config.password }}</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sassResourceApi } from "@/api/sassResource.js";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
          password: "",
        };
      },
    },
  },
  data() {
    return {
      error: false,
      empty: false,
      password: "",
      dataTable: [],
      loading: false,
      confirmLoading: false,
      rowVersion: "",
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      versionTypeList: [
        // {
        //   name: "全部",
        //   value: '',
        // },
        {
          name: "标准版",
          value: 1,
        },
        {
          name: "专业版",
          value: 2,
        },
      ],
    };
  },
  mounted() {
    this.inintModal();
  },
  created() {},
  watch: {},
  methods: {
    inintModal() {
      if (this.config.type === "look") {
        // this.getPassword();
      } else {
        return;
      }
    },
    // limitDecimals(val) {
    //   return val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    // },
    handleCancel() {
      this.$emit("innerHandleCancel");
    },

    checkPassword() {
      this.confirmLoading = true;
      // let password = this.password;
      console.log("传入值");
      console.log(this.config.id);
      let params = {
        id: this.config.id,
        password: this.password,
      };
      sassResourceApi.checkPassword(params).then((res) => {
        if (res.success) {
          console.log("res.data", res.data);
          this.confirmLoading = false;
          if (res.data.checkResult) {
            this.error = false;
            this.$emit("innerHandleOk", "look",res.data.password);
          } else {
            this.error = true;
          }
        } else {
          this.confirmLoading = false;
          this.error = true;
          this.$message.error(res.errorMsg);
        }
      });
    },
    handleOk() {
      if (this.config.type === "get") {
        if (this.password) {
          this.checkPassword();
          this.empty = false;
        } else {
          this.empty = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top_text {
  width: 400px;
  margin: auto;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 900;
  // background-color: aquamarine;
}
.notice {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: red;
  // background-color: aquamarine;
}
.top {
  margin-bottom: 10px;
}
.form_item_contain {
  display: flex;
}
.form_item_span {
  margin-right: 20px;
}
.form_item_left {
  margin-right: 10px;
  // background-color: aqua;
  min-width: 110px;
}
.input_width {
  width: 350px;
}
.carriage {
  display: flex;
  width: 500px;
  // background-color: aqua;
  margin: auto;
  justify-content: space-between;
}
.site {
  width: 240px;
  height: 40px;
  line-height: 40px;
  // background-color: red;
}
.right {
  text-align: right;
}
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}

.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}

.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 10px;
  font-weight: 200;
  height: 60px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
.top-fade-enter, .top-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.top-fade-enter-active,
.top-fade-leave-active {
  transition: opacity 1s;
}
</style>
