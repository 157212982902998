import axios from "@/config/http";

export const sassResourceApi = {
  // 获取table数据
  getTableData(params) {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/tenant/resource/infoByPage",
      {
        params,
      }
    );
  },
  // 获取维护记录table数据
  getDetailTableData(params) {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/tenant/resource/maintenanceRecordByPage",
      {
        params,
      }
    );
  },
  //创建资源
  addResource(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/tenant/resource",
      params
    );
  },
  // 修改资源
  editResource(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/resource",
      params
    );
  },
    // 发布资源
    releaseResource(params) {
      return axios.put(
        "/cloud-platform-backend-facade/front-api/v1/tenant/resource/release",
        params
      );
    },
  // 获取详细资源
  detailResource(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/resource/${params}`
    );
  },


     // 校验密码
     checkPassword(params) {
      console.log('params',params)
      return axios.get(
        "/cloud-platform-backend-facade/front-api/v1/tenant/base/password",
        {params}
      );
    },
  // 获取密码
  getPassword(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/base/password/${params}`
    );
  },
  // 设置密码
  setPassword(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/resource/changeResourcePassword",
      params
    );
  },
  //删除资源
  delResource(params) {
    return axios.delete(
      `/cloud-platform-backend-facade/front-api/v1/tenant/resource/${params}`
    );
  },
};
